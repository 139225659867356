var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "webcam",
      attrs: {
        id: "modal-tall",
        size: "lg",
        title: "International Shipment",
        "hide-footer": "",
        okOnly: "",
        centered: "",
        "modal-class": "pricing-modal"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block fs18 lh28 fw500 mt-8" }, [
                _vm._v(" Take Photo ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "web-camera-container mx-auto", attrs: { id: "app" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCameraOpen && _vm.isLoading,
                  expression: "isCameraOpen && isLoading"
                }
              ],
              staticClass: "camera-loading"
            },
            [
              _c("b-spinner", { staticClass: "align-middle" }),
              _c("strong", [_vm._v("Loading...")])
            ],
            1
          ),
          _vm.isCameraOpen
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading"
                    }
                  ],
                  staticClass: "camera-box",
                  class: { flash: _vm.isShotPhoto }
                },
                [
                  _c("div", {
                    staticClass: "camera-shutter",
                    class: { flash: _vm.isShotPhoto }
                  }),
                  _c("video", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isPhotoTaken,
                        expression: "!isPhotoTaken"
                      }
                    ],
                    ref: "camera",
                    staticClass: "video",
                    attrs: { width: 450, height: 337.5, autoplay: "" }
                  }),
                  _c("canvas", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPhotoTaken,
                        expression: "isPhotoTaken"
                      }
                    ],
                    ref: "canvas",
                    staticClass: "video",
                    attrs: { id: "photoTaken", width: 450, height: 337.5 }
                  })
                ]
              )
            : _vm._e(),
          _vm.isCameraOpen && !_vm.isLoading
            ? _c("div", { staticClass: "camera-shoot mt-10" }, [
                _c(
                  "button",
                  {
                    staticClass: "button camera",
                    attrs: { type: "button" },
                    on: { click: _vm.takePhoto }
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/svg/camera.svg") }
                    })
                  ]
                )
              ])
            : _vm._e(),
          _vm.isPhotoTaken && _vm.isCameraOpen
            ? _c(
                "div",
                { staticClass: "camera-download mt-10" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      attrs: {
                        id: "downloadPhoto",
                        download: "my-photo.jpg",
                        type: "button"
                      },
                      on: { click: _vm.uploadImage }
                    },
                    [
                      _vm.spinUpload
                        ? _c("b-spinner")
                        : _c("span", [_vm._v(" Upload Photo")])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }