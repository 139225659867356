var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "infoModal",
        centered: "",
        "hide-footer": "",
        "hide-header-close": "",
        "no-close-on-backdrop": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "div",
                { staticClass: " text-center", staticStyle: { width: "100%" } },
                [
                  _vm.$store.state.sellerState != 1
                    ? _c(
                        "span",
                        { staticClass: "d-block mt-auto fs18 lh28 fw500" },
                        [_vm._v(" Awaiting Approval from Team Shyplite ")]
                      )
                    : _c(
                        "span",
                        { staticClass: "d-block mt-auto fs18 lh28 fw500" },
                        [_vm._v(" Account Activated ")]
                      )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _vm.$store.state.sellerState != 1
        ? _c("div", [
            _c("p", [
              _vm._v("Your documents have been sent for Account Approval.")
            ]),
            _c("p", [
              _vm._v(" For any queries, you can reach us at "),
              _c(
                "a",
                {
                  staticClass: "fs14",
                  attrs: { href: "mailto:info@shyplite.com" }
                },
                [_vm._v("info@shyplite.com")]
              )
            ])
          ])
        : _vm._e(),
      _vm.$store.state.sellerState == 1
        ? _c("div", [
            _c("p", { staticClass: " text-center" }, [
              _vm._v("Your account has been successfully activated")
            ]),
            _c("p", { staticClass: " text-center" }, [
              _vm._v(
                " You may now Add Funds and Begin Shipping with Shyplite! "
              )
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pt-30 pt-md-30 text-center" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "primary", size: "lg" },
              on: { click: _vm.navigate }
            },
            [_vm._v(" Continue ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }