<template>
	<b-modal
	ref="webcam"
	id="modal-tall"
	size="lg"
	title="International Shipment"
	hide-footer
	okOnly
	v-model="isOpen"
	centered
	modal-class="pricing-modal"
	>
	<template v-slot:modal-header="{ close }">
		<span class="d-block fs18 lh28 fw500 mt-8">
			Take Photo
		</span>
		<!-- Emulate built in modal header close button action -->
		<b-button size="sm" variant="rounded" @click="close()">
			<i class="icon-close-variant"></i>
		</b-button>
	</template>

	<!-- div class="camera-button justify-content-center row">
		<b-button type="button" class="button is-rounded" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera">
			<span v-if="!isCameraOpen">Open Camera</span>
			<span v-else>Close Camera</span>
		</b-button>
	</div> -->

	<div id="app" class="web-camera-container mx-auto">
		

		<div v-show="isCameraOpen && isLoading" class="camera-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong>Loading...</strong>
		</div>

		<div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">

			<div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>

			<video v-show="!isPhotoTaken" class="video" ref="camera" :width="450" :height="337.5" autoplay></video>

			<canvas v-show="isPhotoTaken" id="photoTaken" class="video" ref="canvas" :width="450" :height="337.5"></canvas>
		</div>

		<div v-if="isCameraOpen && !isLoading" class="camera-shoot mt-10">
			<button type="button" class="button camera" @click="takePhoto">
				<img src="@/assets/svg/camera.svg">
			</button>
		</div>

		<div v-if="isPhotoTaken && isCameraOpen" class="camera-download mt-10">
			<b-button id="downloadPhoto" download="my-photo.jpg" class="button" type="button" @click="uploadImage">
				<b-spinner v-if="spinUpload"></b-spinner>
				<span v-else> Upload Photo</span>
			</b-button>
		</div>
	</div>



</b-modal>
</template>

<script>
export default {
	name: "CalcPricing",
	props: ['modalOpen', 'formInfo', 'choiceData'],
	data() {
		return {
			isOpen: this.modalOpen,
			isCameraOpen: false,
			disallowed:false,
			isPhotoTaken: false,
			isShotPhoto: false,
			isLoading: false,
			canvas:null,
			spinUpload:false,
		}
	},

	watch: {
		isOpen(newValue) {
			if(newValue) {
				this.isCameraOpen = false;
				// this.isLoading = false;
				// this.$refs.camera.srcObject = stream;
				this.toggleCamera();
			} else if (!(newValue) && this.disallowed) {
				this.stopCameraStream();
			} else if(!(newValue)) {
				this.isPhotoTaken = false;
				this.canvas = null
				this.stopCameraStream();
			} else {
				this.isPhotoTaken = false;
				this.canvas = null
				this.stopCameraStream();
			}
		}
	},
	methods: {
		toggleCamera() {
			// console.log(this.isCameraOpen)
			if(this.isCameraOpen) {
				this.isCameraOpen = false;
				this.isPhotoTaken = false;
				this.isShotPhoto = false;
				this.stopCameraStream();
			} else {
				this.isCameraOpen = true;
				this.createCameraElement();
			}
		},

		createCameraElement() {
			this.isLoading = true;

			const constraints = (window.constraints = {
				audio: false,
				video: true
			});


			navigator.mediaDevices
			.getUserMedia(constraints)
			.then(stream => {
				this.isLoading = false;
				this.$refs.camera.srcObject = stream;
			})
			.catch(error => {
				this.isLoading = false;
				this.disallowed = true
				this.isOpen = false
			});
		},

		stopCameraStream() {
			let tracks = this.$refs.camera.srcObject.getTracks();

			tracks.forEach(track => {
				track.stop();
			});
		},

		takePhoto() {
			if(!this.isPhotoTaken) {
				this.isShotPhoto = true;

				const FLASH_TIMEOUT = 50;

				setTimeout(() => {
					this.isShotPhoto = false;
				}, FLASH_TIMEOUT);
			}

			this.isPhotoTaken = !this.isPhotoTaken;

			const context = this.$refs.canvas.getContext('2d');
			context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
		},

		async uploadImage() {
			this.spinUpload = true
			const download = document.getElementById("downloadPhoto");
		 	this.canvas = document.getElementById("photoTaken").toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
			const image = await this.urltoFile(this.canvas,'hello.png','text/plain')
			const obj = {}
			obj['photo'] = image
			obj['sentFrom'] = 'camera';
			let fd = new FormData();
	        for (const key of Object.keys(obj)) {
	          fd.append(key, obj[key]);
	        }			


			try {
				const res = await this.axios.post('/register/photoVerification',fd)
				if (res.data.status) {
					this.spinUpload = false
					// this.popToast('booked','Success',res.data.message)
					this.isOpen = false
					this.canvas = null
					this.isPhotoTaken = false
				} else {

					if (res.data.tryCam) {
						this.isOpen = false
						res.data.message += ' Please Try Again!'
						this.disallowed = false;
						this.canvas = null
						this.isPhotoTaken = false
					}
					else {

						this.isOpen = false
						this.canvas = null
						this.disallowed = true
						this.isPhotoTaken = false
						this.stopCameraStream();
					}

					this.spinUpload = false
					// this.popToast('failed','Failed',res.data.message)
				}
			} catch(e) {
				this.spinUpload = false
				this.disallowed = true
				this.isOpen = false
				this.stopCameraStream();
				console.log(e);
			} finally {
				this.$parent.selfieTaken = true
				this.popToast('booked','Success',"Photo Uploaded Successfully ")
			}

		},

		urltoFile(url, filename, mimeType){
	        return (fetch(url)
	            .then(function(res){return res.arrayBuffer();})
	            .then(function(buf){return new File([buf], filename,{type:mimeType});})
	        );
	    }

	}
};
</script>

<style scoped>
body {
	display: flex;
	justify-content: center;
}
.video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.web-camera-container {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 500px;


	.camera-button {
		margin-bottom: 2rem;
	}

	.camera-box {    
		.camera-shutter {
			opacity: 0;
			width: 450px;
			height: 337.5px;
			background-color: #fff;
			position: absolute;

			&.flash {
				opacity: 1;
			}
		}
	}

	.camera-shoot {
		margin: 1rem 0;

		button {
			height: 60px;
			width: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;

			img {
				height: 35px;
				object-fit: cover;
			}
		}
	}

	.camera-loading {
		overflow: hidden;
		height: 100%;
		position: absolute;
		width: 100%;
		min-height: 150px;
		margin: 3rem 0 0 -1.2rem;

		ul {
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 999999;
			margin: 0;
		}

		.loader-circle {
			display: block;
			height: 14px;
			margin: 0 auto;
			top: 50%;
			left: 100%;
			transform: translateY(-50%);
			transform: translateX(-50%);
			position: absolute;
			width: 100%;
			padding: 0;

			li {
				display: block;
				float: left;
				width: 10px;
				height: 10px;
				line-height: 10px;
				padding: 0;
				position: relative;
				margin: 0 0 0 4px;
				background: #999;
				animation: preload 1s infinite;
				top: -50%;
				border-radius: 100%;

				&:nth-child(2) {
					animation-delay: .2s;
				}

				&:nth-child(3) {
					animation-delay: .4s;
				}
			}
		}
	}

	@keyframes preload {
		0% {
			opacity: 1
		}
		50% {
			opacity: .4
		}
		100% {
			opacity: 1
		}
	}
}
.camera {
	background: #006eff !important;
    border-radius: 10px !important;
}
</style>