<template>
  <b-modal
    id="infoModal"
    centered
    v-model="isOpen"
    hide-footer
    hide-header-close
    no-close-on-backdrop
  >
    <template v-slot:modal-header="{ close }" >
      <div class=" text-center" style="width: 100%;">
        <span class="d-block mt-auto fs18 lh28 fw500" v-if="$store.state.sellerState != 1">
          Awaiting Approval from Team Shyplite
        </span>
        <span class="d-block mt-auto fs18 lh28 fw500" v-else>
          Account Activated
        </span>
      </div>
    </template>
    <div v-if="$store.state.sellerState != 1">
      <p>Your documents have been sent for Account Approval.</p>
      <p>
        For any queries, you can reach us at
        <a href="mailto:info@shyplite.com" class="fs14">info@shyplite.com</a>
      </p>
    </div>
    
    <div v-if="$store.state.sellerState == 1">
      <p class=" text-center">Your account has been successfully activated</p>
      <p class=" text-center">
        You may now Add Funds and Begin Shipping with Shyplite!
      </p>
    </div>
    
    <div class="pt-30 pt-md-30 text-center">
      <b-button @click="navigate" variant="primary" size="lg">
        Continue
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "KYCInfoModal",
  data() {
    return {
      isOpen: this.modalOpen,
    };
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigate() {
      this.$router.push("/dashboard");
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="css" scoped>
  /deep/.modal-header {
      padding: 1.75rem 2.5rem 2rem 2.5rem
    }
 
</style>
